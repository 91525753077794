
import Vue from "vue";
import ProfileStatistics from "./ProfileStatistics.vue";
import ProfileActions from "./ProfileActions.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ProfileInfoMenu",
  components: {
    ProfileStatistics,
    ProfileActions
  },
  data() {
    return {
      menuItems: [
        {
          link: "",
          icon: "add-user",
          label: this.$store.getters.user.details.position
        },
        {
          link: "/mentorcasts",
          icon: "users",
          label: (this as any).$t("profile.menuItemsMyMentorcasts")
        },
        {
          link: "/sessions",
          icon: "time-circle",
          label: (this as any).$t("profile.menuItemsMySessions")
        },
        {
          link: "/agenda",
          icon: "document",
          label: (this as any).$t("profile.menuItemsMyAgenda")
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  }
});
